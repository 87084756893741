<template>
  <div>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Owner"
            label-for="type"
          >
            <v-select
              id="type"
              v-model="ownerId"
              label="name"
              :reduce="owner => owner"
              :filterable="false"
              :options="users"
              @search="onSearch"
              @input="selectOwner($event)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="User"
            label-for="type"
          >
            <v-select
              id="type"
              v-model="userID"
              label="name"
              :filterable="false"
              :reduce="merchant => merchant"
              :options="users"
              @search="onSearch"
              @input="setSelected($event)"
            />
            <div v-if="validationErrors.user">
              <p class="text-danger">
                {{ validationErrors.user }}
              </p>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <validation-provider
            name="Phone Number"
          >
            <b-form-group
              label="Phone Number"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userForm.phone_number"
                placeholder="Phone Number"
              />
              <div v-if="validationErrors.phone_number">
                <p class="text-danger">
                  {{ validationErrors.phone_number }}
                </p>
              </div>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Full Name"
              label-for="CC"
            >
              <b-form-input
                id="CC"
                v-model="userForm.full_name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Full Name"
              />
              <div v-if="validationErrors.full_name">
                <p class="text-danger">
                  {{ validationErrors.full_name }}
                </p>
              </div>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addUser()"
          >
            Add
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import previewImage from '@/global-compositions/previewImage'
import friends from '../friends'

export default {
  components: { vSelect },
  mixins: [previewImage],
  data() {
    return {
      userForm: {},
      merchantType: [],
      users: [],
      owners: [],
      id: this.$store.state.generalIds.id,
      validationErrors: {
        user: '',
        phone_number: '',
        full_name: '',
      },
      userID: '',
      ownerId: '',
      loader: false,
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    const { userForm } = friends()

    return {
      getValidationState,
      userForm,
    }
  },
  mounted() {
    this.viewMerchant()
  },
  methods: {
    closeModal() {
      this.userForm = {}
      this.userID = ''
      this.ownerId = ''
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('merchant')
    },
    setSelected(value) {
      this.userForm.phone_number = this.users.find(owner => owner.id === value.id).phone
      this.userForm.user_id = this.users.find(owner => owner.id === value.id).id
    },
    selectOwner(value) {
      this.userForm.owner_id = this.owners.find(owner => owner.id === value.id).id
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search)
      }
    },
    search(loading, search) {
      axios.get(`get-users?search=${search}`).then(res => {
        if (search.length >= 2) {
          this.users = res.data.data
          this.owners = res.data.data
        }
        loading(false)
      })
    },
    isEmpty(obj) {
      return Object.keys(obj).forEach(key => {
        // eslint-disable-next-line no-param-reassign
        delete obj[key]
      })
    },
    addUser() {
      if (this.id) {
        this.loader = true
        axios.put(`friends/${this.id}`, this.userForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.friendsTable.getAllData()
            this.closeModal()
            this.$store.commit('generalIds/SET_ID', null)
            this.$bvModal.hide('merchant')
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error) {
            this.validationErrors.user = error.response.data.errors.user_id.toString()
            this.validationErrors.phone_number = error.response.data.errors.phone_number.toString()
            this.validationErrors.full_name = error.response.data.errors.full_name.toString()
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        axios.post('friends', this.userForm).then(res => {
          this.$parent.$parent.$parent.$refs.friendsTable.getAllData()
          if (res.status === 200 || res.status === 201) {
            this.isEmpty(this.userForm)
            this.$bvModal.hide('merchant')
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error) {
            this.validationErrors.user = error.response.data.errors.user_id.toString()
            this.validationErrors.phone_number = error.response.data.errors.phone_number.toString()
            this.validationErrors.full_name = error.response.data.errors.full_name.toString()
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
    viewMerchant() {
      if (this.id) {
        axios.get(`friends/${this.id}`).then(res => {
          this.userForm = res.data.data
          this.userID = res.data.data.user_name
          this.ownerId = res.data.data.owner_name
        })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
