<template>
  <div>
    <general-table
      ref="friendsTable"
      :api-url="APIURL"
      :add-type="addType"
      :view-content="viewContent"
      :type="type"
      :columns="columns"
      guard="friends"
    />
    <div>
      <div>
        <b-modal
          id="merchant"
          ref="genmodal"
          hide-footer
          centered
          size="lg"
          no-close-on-backdrop
          title="Friend"
        >
          <add-edit-friend />
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditFriend from './control-friends/AddEditFriend.vue'

export default {
  components: { GeneralTable, AddEditFriend },
  data() {
    return {
      APIURL: 'friends',
      addType: 'Add Friend',
      addComponentName: 'add-friend',
      editComponent: 'edit-friend',
      viewContent: false,
      type: 'modal',
      columns: [
        { key: 'id' },
        { key: 'owner_name', label: 'From' },
        { key: 'full_name', label: 'Full Namee' },
        { key: 'phone_number', label: 'phone Number' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
