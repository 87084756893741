import { ref } from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'

export default function merchan() {
  const userForm = ref({
    user_id: '',
    owner_id: '',
    full_name: '',
    phone_number: '',
  })

  const viewData = ref({})
  const { id } = store.state.generalIds
  const showUser = () => {
    if (id) {
      axios.get(`friends/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      }).then(res => {
        viewData.value = res.data.data
      })
    }
  }
  showUser()

  const resolveActiveUser = role => {
    if (role === true) return 'Active'

    return 'Not Active'
  }

  return {
    userForm,
    viewData,
    resolveActiveUser,
  }
}
